label {
    font-weight: 200;
    color: royalblue;
}

p {
    font-weight: 400;
    color: black;
}



input[type=text],
select,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    color:black;

}