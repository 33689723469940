


.navbar-brand {
  padding: 14px 22px;
  display: inline-block;
  background-color: #ee0979;
}

.container {
  padding: 100px;
}

input[type=file],
select {
  width: 90%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  /* border: 1px solid #ccc;/ */
  background-color: #ebebeb;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=text],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;

}

label {
  font-weight: bold;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}



h1 {
  font-size: 25px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

p{
  font-size: 18px;
}